<template>
  <div :class="['logo', {'logo--margin-none' : margin === 'none'}]">
		<!-- <h1>Anna Robbins</h1>
		<Paragraph>Front-End Developer</Paragraph> -->
	</div>
</template>

<script>
export default {
	props: {
		margin: String,
	},
}
</script>

<style lang="scss" scoped>
.logo{
	width: 100%;
	height: 150px;
	background: #fff;
	padding-top: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	h1{
		font-family: 'Abril Fatface', cursive;
		color:#000;
		font-size: 60px;
		margin:0;
		font-weight: normal;
	}
}
.logo--margin-none{
	padding-top: 0;
}
</style>

<!-- Used in : AR, DS -->